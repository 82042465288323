import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby"


export default function Other ({ data }) {
	const conferences = data.conferences.edges[0].node.childServicesJson.conferences;
	const teaching = data.teaching.edges[0].node.childServicesJson.teaching;

	return (
		<Layout>
			<SEO title="Other" />

			<h2 className="text-center">Reviewing</h2>

			<div className="container text-center mb-5">
				{conferences.map((conference, index) => (
					<div className="row my-2 py-2 mx-0 mx-md-5" key={conference.name_short + "_" + index}>
						<div className="container">
							<div className="row g-0">
								<div className="col-12 col-md-10 mx-auto">
									<div className="fs-3">
										<b>{conference.name_short}</b>
									</div>
								</div>
							</div><div className="row">
								<div className="col-12 fs-5">
									<div >
										{conference.name}
									</div>
								</div>
							</div>
							<div className="row text-start">

								<div className="col-md-4 col-12 mx-auto text-center">
										{conference.years.map((year, index2) => (
											<span className="mx-2" key={"review" + index + "_" + index2}><b>{year.year}</b></span>
										))}
								</div>
							</div>
							<div className="row">
								<div className="col-md-6 col-12 mx-auto mt-2 border-bottom">

								</div>
							</div>
						</div>

					</div>
				))}
			</div>

			<h2 className="text-center mt-5">Teaching</h2>

			<div className="container text-center mb-5">
				{teaching.map(course => (
					course.semesters.map((term, index) => (
						<div className="row my-3 mx-0 mx-md-5" key={"teaching" + index}>
							<div className="col-12 col-md-10 mx-auto">
								<span className="fw-bold">{course.name} ({course.name_short})</span> - {term.role} - {term.semester} <br/>{term.comment}
							</div>
						</div>
					))

				))}
			</div>
		</Layout>
	);
}


export const query = graphql`
query Other {
	  teaching: allFile(filter: {name: {eq: "teaching"}}) {

    edges {
      node {
        childServicesJson {
          teaching {
            name
            name_short
            semesters {
            	semester
            	role
            	comment
            }
          }
        }
      }
    }
  }
  conferences: allFile(filter: {name: {eq: "reviewing"}}) {
    edges {
      node {
        childServicesJson {
          conferences {
            name
            name_short
            years {
            	year
            	comment
            }
          }
        }
      }
    }
  }
}

`

